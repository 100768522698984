import { IDocumentType } from './interfaces/IDocumentType';

export class DocumentTypeData implements IDocumentType {
	public DocumentTypeId = 0;
	public Name = '';

	constructor(documentType = null) {
		if (documentType) {
			this.InitialisePopulatedItem(documentType);
		}
	}

	public InitialisePopulatedItem(documentType: any): void {
		this.DocumentTypeId = documentType.DocumentTypeId;
		this.Name = documentType.Name;
	}
}
