import { IComplianceDocument } from './interfaces/IComplianceDocument';
import moment from 'moment';
import { IDocumentType } from './interfaces/IDocumentType';
import { DocumentTypeData } from './DocumentTypeData';
import { DropDownType } from '@/classes/generics/classes/DropDownType';
import { ZoneStatus } from '@/classes/supplierZones/zoneStatus';
import { IssuingAuthorityEnum } from '@/classes/generics/enums/IssuingAuthorityEnum';
import { DocumentStatus } from '@/classes/supplierZones/DocumentStatus';

export class ComplianceDocument implements IComplianceDocument {
	public ComplianceId: number | undefined;
	public DocumentTypeId: number | undefined;
	public Filename = '';
	public Extension: string | undefined;
	public UploadedBy: string | undefined;
	public DocumentSize: number | undefined;
	public ExpiryDate?: Date | string | undefined = undefined;
	// public ExpiryDateString?: string = undefined; //new Date().toISOString().substr(0, 10);
	private _expiryDateString?: string = undefined;

	get ExpiryDateString(): string | undefined {
		return this._expiryDateString;
	}

	set ExpiryDateString(date: string | undefined) {
		this.ExpiryDate = date || undefined;
		this._expiryDateString = date;
	}

	public UploadedDate: string | undefined;
	public Filepath: string | undefined;
	public File: any;
	public ActualFile: File | undefined = undefined;
	public SiteId?: number | null | undefined = null;
	public SupplierId = 0;

	public Status: undefined | null | ZoneStatus = null;
	public DocumentStatus: DocumentStatus = DocumentStatus.InDate;

	public RejectionReason = '';

	public DocumentType: DropDownType = new DropDownType('', 0);
	public DocumentTypeName = '';
	public DocumentSourceId: IDocumentType = new DocumentTypeData();

	// New Fields For Jack B

	public StartDate?: Date | Date | string = undefined;

	private _startDateString?: string = undefined;

	get StartDateString(): string | undefined {
		return this._startDateString;
	}

	set StartDateString(date: string | undefined) {
		this.StartDate = date || undefined;
		this._startDateString = date;
	}
	PolicyNumber = undefined;
	Value = 0;

	// Waste Carriers Licence
	LicenceHolder = undefined;
	IssuingAuthority: number | undefined;
	LicenceType: number | undefined;

	// Getters / Setters

	private _issuingAuthoritySelect?: DropDownType = new DropDownType('', 0);

	get IssuingAuthoritySelect(): DropDownType | undefined {
		return this._issuingAuthoritySelect;
	}

	set IssuingAuthoritySelect(type: DropDownType | undefined) {
		this.IssuingAuthority = type?.Value;
		this._issuingAuthoritySelect = type;
	}

	private _licenceType: Array<DropDownType> = [];
	get LicenceTypeSelect(): Array<DropDownType> {
		return this._licenceType;
	}
	set LicenceTypeSelect(value: Array<DropDownType>) {
		this._licenceType = value;

		this.LicenceType = this._licenceType.reduce(
			(sum, current) => sum + current.Value,
			0
		);
	}

	constructor(complianceDocument: any = null) {
		if (complianceDocument) {
			this.InitialisePopulatedItem(complianceDocument);
		}
	}

	public InitialisePopulatedItem(complianceDocument: any) {
		this.ComplianceId = complianceDocument.ComplianceId;
		this.DocumentTypeId = complianceDocument.DocumentTypeId;
		this.DocumentTypeName = complianceDocument.DocumentTypeName;
		this.LicenceType = complianceDocument.LicenceType;

		this.Filename = complianceDocument.Filename;
		this.Extension = complianceDocument.Extension;
		this.UploadedBy = complianceDocument.UploadedBy;
		this.DocumentSize = complianceDocument.DocumentSize;

		this.ExpiryDate = complianceDocument.ExpiryDate;
		this.StartDate = complianceDocument.StartDate;

		if (complianceDocument.StartDate) {
			this.StartDateString = new Date(complianceDocument.StartDate)
				.toISOString()
				.substr(0, 10);
		}

		if (complianceDocument.ExpiryDate) {
			this.ExpiryDateString = new Date(complianceDocument.ExpiryDate)
				.toISOString()
				.substr(0, 10);
		}

		this.UploadedDate = moment(complianceDocument.UploadedDate).format(
			'DD MMM YYYY'
		);

		this.SiteId = complianceDocument.SiteId;
		this.Filepath = complianceDocument.Filepath;

		this.SupplierId = complianceDocument.SupplierId;

		this.DocumentType = complianceDocument.DocumentType;

		this.IssuingAuthoritySelect = new DropDownType(
			IssuingAuthorityEnum[complianceDocument.IssuingAuthority],
			complianceDocument.IssuingAuthority
		);

		if (complianceDocument.LicenceType) {
			this.LicenceTypeSelect =
				complianceDocument.LicenceTypeDropDown ||
				complianceDocument.LicenceTypeSelect ||
				this.LicenceTypeSelect;
		}

		this.Status = complianceDocument.Status;
		this.DocumentStatus = complianceDocument.DocumentStatus;
		this.RejectionReason = complianceDocument.RejectionReason;

		this.LicenceHolder = complianceDocument.LicenceHolder;
		this.PolicyNumber = complianceDocument.PolicyNumber;
		this.Value = complianceDocument.Value;

		this.IssuingAuthority = complianceDocument.IssuingAuthority;
	}
}
