import { axiosBase } from '../base/baseAxios';
import { AxiosResponse } from 'axios';

export class HttpService {
	public isLoading = false;

	public Get<T>(url: string): Promise<T> {
		this.isLoading = true;
		return new Promise((resolve, reject) => {
			axiosBase.get(`${url}`).then(
				(response: AxiosResponse<T>) => {
					resolve(response.data);
					this.isLoading = false;
				},
				(err: any) => {
					reject(err);
					this.isLoading = false;
				}
			);
		});
	}

	public Post<T>(url: string, data: T): Promise<T> {
		this.isLoading = true;

		return new Promise((resolve, reject) => {
			axiosBase.post(`${url}`, data).then(
				(response: AxiosResponse<T>) => {
					resolve(response.data);
					this.isLoading = false;
				},
				(err: any) => {
					reject(err);
					this.isLoading = false;
				}
			);
		});
	}

	public Put<T, T2 = T>(url: string, data: T): Promise<T2> {
		this.isLoading = true;

		return new Promise((resolve, reject) => {
			axiosBase.put(`${url}`, data).then(
				(response: AxiosResponse<T2>) => {
					resolve(response.data);
					this.isLoading = false;
				},
				(err: any) => {
					reject(err);
					this.isLoading = false;
				}
			);
		});
	}

	public Delete<T>(url: string): Promise<T> {
		this.isLoading = true;

		return new Promise((resolve, reject) => {
			axiosBase.delete(`${url}`).then(
				(response: AxiosResponse<T>) => {
					resolve(response.data);
					this.isLoading = false;
				},
				(err: any) => {
					reject(err);
					this.isLoading = false;
				}
			);
		});
	}
}
