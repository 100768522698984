var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Mandatory Documents")]),_c('v-row',{staticClass:"mandatory-cards",attrs:{"no-gutters":""}},_vm._l((_vm.mandatoryDocuments),function(document,index){return _c('v-card',{key:index,style:(("border-left: 5px solid " + (_vm.GetDocumentStatusIndicatorColour(
					document.DocumentStatus
				)))),attrs:{"elevation":"2","outlined":"","max-width":"22%","min-value":"310"},on:{"click":function($event){return _vm.DownloadDocument(document)}}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-card-title',[_vm._v(_vm._s(document.DocumentType.Name))]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card-subtitle',{staticClass:"font-weight-medium"},[_vm._v("Start Date")]),_c('v-card-text',[_vm._v(_vm._s(!document.StartDate ? 'N/A' : document.StartDate))])],1),_c('v-col',[_c('v-card-subtitle',{staticClass:"font-weight-medium text-right"},[_vm._v("Expiry Date")]),_c('v-card-text',{staticClass:"text-right"},[_vm._v(_vm._s(!document.ExpiryDate ? 'N/A' : document.ExpiryDate))]),_c('v-card-text',{staticClass:"text-right"})],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(("" + (_vm.documentStatus[document.DocumentStatus] .replace(/[A-Z]/g, ' $&') .trim()))))])])],1)}),1),_c('v-row',[_c('v-data-table',{attrs:{"items":_vm.documents,"headers":_vm.headers,"item-key":"Filename","items-per-page":15,"mobile-breakpoint":700,"loading":(_vm.documents.length === 0 || _vm.isGettingDocument) &&
					!_vm.headerModule.GetSearch,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"secondary","indeterminate":"","rounded":""}})]},proxy:true},{key:"item",fn:function(ref){
					var item = ref.item;
return [_c('v-tooltip',{attrs:{"activator":item.Status === 1 || !item.Status
							? null
							: '#document-row-' + item.ComplianceId,"top":"","nudge-left":"200"}},[[(item.Status)?_c('span',[_vm._v(_vm._s(item.Status == _vm.zoneStatus.ReviewRejected ? _vm.zoneStatus[item.Status] .match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g) .join(' ') + ' - ' + item.RejectionReason : _vm.zoneStatus[item.Status] .match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g) .join(' ')))]):_vm._e()]],2),_c('tr',{attrs:{"id":'document-row-' + item.ComplianceId},on:{"click":function($event){return _vm.DownloadDocument(item)}}},[_c('td',{staticStyle:{"position":"relative"}},[(item.Status)?_c('div',{style:(("width: 5px; height: 100%; position: absolute; background: " + (_vm.GetZoneStatusIndicatorColour(
									item.Status
								)) + "; top: 0; bottom: 300px; left: 0; border-radius: 8px;"))}):_vm._e(),_vm._v(" "+_vm._s(item.Filename)+" ")]),_c('td',[_vm._v(_vm._s(item.DocumentType.Name))]),_c('td',[_vm._v(_vm._s(item.ExpiryDate || 'No Expiry'))]),_c('td',[_vm._v(_vm._s(item.UploadedDate))])])]}}])})],1),(!_vm.authModule.IsReviewer)?_c('v-speed-dial',{attrs:{"id":"support-fab","bottom":true,"right":true,"direction":'top',"open-on-hover":false},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('template',{slot:"activator"},[_c('v-tooltip',{attrs:{"left":"","color":"secondary","close-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"fab-raise-ticket","color":"secondary","fab":""},on:{"click":function($event){return _vm.ShowUploadModal()}},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-upload")])],1)]}}],null,false,4123228750)},[_c('span',[_vm._v("Upload Document")])])],1)],2):_vm._e(),(_vm.showUpload)?_c('UploadDocument',{attrs:{"complianceDocument":_vm.complianceDocument},on:{"close-modal":_vm.CloseModal,"update:complianceDocument":function($event){_vm.complianceDocument=$event},"update:compliance-document":function($event){_vm.complianceDocument=$event}},model:{value:(_vm.showUpload),callback:function ($$v) {_vm.showUpload=$$v},expression:"showUpload"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }