













































































































































































































































































































































































































































































import { DropDownType } from '@/classes/generics/classes/DropDownType';
import { SupplierDocumentTypeEnum } from '@/classes/generics/enums/SupplierDocumentTypeEnum';
import { ConversionService } from '@/services/conversion/conversionService';
import { ComplianceDocumentService } from '@/services/documents/complianceDocumentService';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

import { SuppliersModule } from '@/store/suppliers/suppliers';
import { ComplianceDocument } from '@/classes/documents/ComplianceDocuments/ComplianceDocument';
import { IssuingAuthorityEnum } from '@/classes/generics/enums/IssuingAuthorityEnum';
import { LicenceTypeEnum } from '@/classes/generics/enums/LicenceTypeEnum';
import { AuthStore } from '@/store/auth/auth';
import { ZoneStatus } from '@/classes/supplierZones/zoneStatus';
import { IComplianceDocument } from '@/classes/documents/ComplianceDocuments/interfaces/IComplianceDocument';

@Component
export default class UploadDocument extends Vue {
	@Prop() private value: any;

	@PropSync('complianceDocument', { type: Object })
	syncedComplianceDocument!: ComplianceDocument;

	get Show() {
		return this.value;
	}

	set Show(value) {
		this.$emit('input', value);

		if (!value) {
			this.form.uploadForm.reset();
		}
	}

	authModule = AuthStore;
	documentStatus = ZoneStatus;
	isFormValid = false;
	showRejectPopup = false;

	isLoading = false;

	insuranceTypes: Array<SupplierDocumentTypeEnum> = [
		SupplierDocumentTypeEnum.MotorInsurances,
		SupplierDocumentTypeEnum.PublicLiabilityInsurance,
		SupplierDocumentTypeEnum.EmployersLiabilityInsurance,
		SupplierDocumentTypeEnum.WasteCarrierLicence,
	];

	supplierDocumentTypes: typeof SupplierDocumentTypeEnum = SupplierDocumentTypeEnum;
	supplierDocumentTypesMapped: DropDownType[] = [];

	issuingAuthorityTypes: typeof IssuingAuthorityEnum = IssuingAuthorityEnum;
	issuingAuthorityTypesMapped: DropDownType[] = [];

	licenceTypeSelectTypes: typeof LicenceTypeEnum = LicenceTypeEnum;
	licenceTypeSelectTypesMapped: DropDownType[] = [];

	isFullscreen = false;
	form: any;
	valid = false;
	datePicker = false;
	startDatePicker = false;
	conversionService = new ConversionService();
	complianceDocumentService = new ComplianceDocumentService();

	mounted() {
		this.form = this.$refs;

		this.supplierDocumentTypesMapped = this.conversionService.EnumToDropDown(
			this.supplierDocumentTypes
		);

		this.issuingAuthorityTypesMapped = this.conversionService.EnumToDropDown(
			this.issuingAuthorityTypes
		);

		this.licenceTypeSelectTypesMapped = this.conversionService.EnumToDropDown(
			this.licenceTypeSelectTypes
		);
	}

	onResize() {
		if (window.innerWidth > 992) {
			this.isFullscreen = false;
			return;
		}

		if (window.innerWidth <= 992) {
			this.isFullscreen = true;
			return;
		}
	}

	ActionDocument(confirmed: boolean) {
		this.syncedComplianceDocument.Status = confirmed
			? null
			: ZoneStatus.ReviewRejected;

		this.showRejectPopup = false;

		this.complianceDocumentService
			.GetDocumentData<Array<IComplianceDocument>>(
				`${confirmed ? 'ConfirmReview' : 'ReviewRejected'}?documentId=${
					this.syncedComplianceDocument?.ComplianceId
				}${
					confirmed
						? ''
						: '&rejectionReason=' +
						  this.syncedComplianceDocument?.RejectionReason
				}`
			)
			.then(() => {
				this.$emit('close-modal', { close: true, update: true });
				this.Show = false;
			});
	}

	DownloadDocument() {
		const supplierId = SuppliersModule.GetSelectedSupplier.SupplierId;
		this.complianceDocumentService.GetDocumentDownloadSave(
			`Download?supplierId=${supplierId}&ComplianceId=${this.syncedComplianceDocument.ComplianceId}`,
			this.syncedComplianceDocument.Filename
		);
	}

	async Upload() {
		if (
			this.syncedComplianceDocument.ActualFile?.type !== 'application/pdf' &&
			!this.syncedComplianceDocument.Filename
		) {
			alert('Only pdf files are allowed!');
			return;
		}

		this.isLoading = true;
		this.syncedComplianceDocument.Filename =
			this.syncedComplianceDocument.ActualFile?.name ||
			this.syncedComplianceDocument.Filename;

		if (this.syncedComplianceDocument.ActualFile?.type) {
			this.syncedComplianceDocument.Extension = this.syncedComplianceDocument.ActualFile.type.substr(
				this.syncedComplianceDocument.ActualFile.type.lastIndexOf('/') + 1,
				this.syncedComplianceDocument.ActualFile.type.length
			);

			const byteArray = await this.conversionService.FileToByteArray(
				this.syncedComplianceDocument.ActualFile
			);
			this.syncedComplianceDocument.File = byteArray;
		}

		this.syncedComplianceDocument.DocumentTypeId =
			this.syncedComplianceDocument.DocumentType?.Value || 0;

		this.UploadInternal();
	}

	UploadInternal() {
		this.complianceDocumentService
			.UploadFile(
				`RequestReview/?supplierId=${SuppliersModule.GetSelectedSupplier.SupplierId}`,
				this.syncedComplianceDocument
			)
			.then(data => {
				const newData = new ComplianceDocument(data);
				Object.assign(this.syncedComplianceDocument, newData);
			})
			.finally(() => {
				this.$emit('close-modal', { close: true, update: true });
				this.Show = false;
				this.isLoading = false;
			});
	}

	EditInternal() {
		this.isLoading = true;
		this.syncedComplianceDocument.DocumentTypeId =
			this.syncedComplianceDocument.DocumentType?.Value || 0;

		this.complianceDocumentService
			.EditFile(
				`RequestReview/?supplierId=${SuppliersModule.GetSelectedSupplier.SupplierId}&complianceId=${this.syncedComplianceDocument.ComplianceId}`,
				this.syncedComplianceDocument
			)
			.then(data => {
				this.syncedComplianceDocument = new ComplianceDocument(data);
			})
			.finally(() => {
				this.$emit('close-modal', { close: true, update: true });
				this.Show = false;
				this.isLoading = false;
			});
	}

	DeleteInternal() {
		this.complianceDocumentService
			.DeleteFile(
				`RequestReview/?supplierId=${SuppliersModule.GetSelectedSupplier.SupplierId}&complianceId=${this.syncedComplianceDocument.ComplianceId}`
			)
			.then(data => {
				this.syncedComplianceDocument = new ComplianceDocument(data);
			})
			.finally(() => {
				this.$emit('close-modal', { close: true, update: true });
				this.Show = false;
			});
	}

	CloseModal() {
		this.Show = false;
		this.$emit('close-modal', { close: true, update: false });
	}
}
