import { DropDownType } from '@/classes/generics/classes/DropDownType';

export class ConversionService {
	public EnumToDropDown(items: any): DropDownType[] {
		const enumKeys = Object.keys(items);

		const keys = enumKeys
			.splice(enumKeys.length / 2, enumKeys.length)
			.map((val: any) => {
				const enumValue = val ? val : '';

				const dropDownValue = {
					Value: 0,
					Name: '',
				};

				val = enumValue.match(/[A-Z][a-z]+|[0-9]+/g);

				if (val) {
					dropDownValue.Name = enumValue.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
				} else {
					dropDownValue.Name = enumValue;
				}

				if (
					!parseInt(dropDownValue.Name, undefined) &&
					dropDownValue.Name !== '0'
				) {
					dropDownValue.Value = items[enumValue]; // +1 because all enums start at 1 - issue with selects recognising 0 as a value
				}

				return dropDownValue;
			});

		return keys
			.filter(xx => xx.Value != null)
			.sort((first, second) => 0 - (first.Name > second.Name ? -1 : 1));
	}

	public FileToByteArray(file: any) {
		return new Promise((resolve, reject) => {
			try {
				const reader = new FileReader();
				const fileByteArray: any[] = [];
				reader.readAsArrayBuffer(file);
				reader.onloadend = evt => {
					if (evt.target?.readyState == FileReader.DONE) {
						const arrayBuffer = evt.target.result as any,
							array = new Uint8Array(arrayBuffer);
						let byte;
						for (byte of array) {
							fileByteArray.push(byte);
						}
					}
					resolve(fileByteArray);
				};
			} catch (e) {
				reject(e);
			}
		});
	}
}
