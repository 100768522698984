import { axiosBase } from '../base/baseAxios';
import { AxiosResponse } from 'axios';
import { ComplianceDocument } from '@/classes/documents/ComplianceDocuments/ComplianceDocument';
export class ComplianceDocumentService {
	public GetDocumentData<T>(url: string): Promise<T> {
		return new Promise((resolve, reject) => {
			axiosBase.get(`Documents/${url}`).then(
				(response: AxiosResponse<T>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}

	public GetMandatoryDocumentData<T>(url: string): Promise<T> {
		return new Promise((resolve, reject) => {
			axiosBase.get(`${url}`).then(
				(response: AxiosResponse<T>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}

	public GetDocumentDownloadSave(url: string, filename: string): Promise<any> {
		return new Promise((resolve, reject) => {
			axiosBase
				.get(`Documents/${url}`, {
					responseType: 'blob',
				})
				.then(
					(response: AxiosResponse<any>) => {
						const splitHeaderType = response.headers['content-type'].split('/');
						const blob = new Blob([response.data]);

						if (window.navigator.userAgent.indexOf('Trident/') > 0) {
							window.navigator.msSaveBlob(blob, filename); //  + '.' + splitHeaderType[splitHeaderType.length - 1]
						} else {
							const url = window.URL.createObjectURL(blob);
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute(
								'download',
								filename + '.' + splitHeaderType[splitHeaderType.length - 1]
							); // + '.' + splitHeaderType[splitHeaderType.length - 1]
							document.body.appendChild(link);
							link.click();
						}

						resolve(response.data);
					},
					(err: any) => {
						reject(err);
					}
				)
				.catch(err => {
					console.log(err);
				});
		});
	}

	public UploadFile(
		url: string,
		complianceDocument: ComplianceDocument
	): Promise<any> {
		return new Promise((resolve, reject) => {
			axiosBase.post(`Documents/${url}`, complianceDocument).then(
				(response: AxiosResponse<any>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}

	public EditFile(
		url: string,
		complianceDocument: ComplianceDocument
	): Promise<any> {
		return new Promise((resolve, reject) => {
			axiosBase.put(`Documents/${url}`, complianceDocument).then(
				(response: AxiosResponse<any>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}

	public DeleteFile(url: string): Promise<any> {
		return new Promise((resolve, reject) => {
			axiosBase.delete(`Documents/${url}`).then(
				(response: AxiosResponse<any>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}
}
