
































































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { IComplianceDocument } from '@/classes/documents/ComplianceDocuments/interfaces/IComplianceDocument';
import { ComplianceDocument } from '@/classes/documents/ComplianceDocuments/ComplianceDocument';
import { ZoneStatus } from '@/classes/supplierZones/zoneStatus';
import { DocumentStatus } from '@/classes/supplierZones/DocumentStatus';

// Classes

// Services
import { ComplianceDocumentService } from '@/services/documents/complianceDocumentService';
import { HttpService } from '@/services/generic/httpService';

// Modules
import { SuppliersModule } from '@/store/suppliers/suppliers';
import { HeaderModule } from '@/store/header/header';
import { AuthStore } from '@/store/auth/auth';

// Templates
import UploadDocument from '@/components/modals/documents/uploadDocument.vue';
import { number } from '@amcharts/amcharts4/core';

@Component({
	components: { UploadDocument },
})
export default class Documents extends Vue {
	headerModule = HeaderModule;
	complianceDocumentService = new ComplianceDocumentService();
	httpService = new HttpService();

	subscription: any;
	authModule = AuthStore;

	complianceDocument = new ComplianceDocument();
	mandatoryDocument = new ComplianceDocument();
	selectedDocument = new ComplianceDocument();

	mandatoryDocuments: IComplianceDocument[] = [];
	documents: ComplianceDocument[] = [];
	fab: any = false;
	showTooltip = false;

	zoneStatus = ZoneStatus;
	documentStatus = DocumentStatus;

	backupDocuments: ComplianceDocument[] = [];

	@Watch('headerModule.GetSearch')
	onSearchChanged(val: string) {
		if (val && this.backupDocuments.length !== 0) {
			const filteredDocuments = this.backupDocuments.filter(
				xx =>
					xx.Filename.toLowerCase().includes(val.toLowerCase()) ||
					xx.ExpiryDate?.toString()
						.toLowerCase()
						.includes(val.toLowerCase()) ||
					xx.DocumentType?.Name.toString()
						.toLowerCase()
						.includes(val.toLowerCase()) ||
					xx.UploadedDate?.toString()
						.toLowerCase()
						.includes(val.toLowerCase())
			);

			this.documents = filteredDocuments;
		} else {
			this.documents = this.backupDocuments;
		}
	}

	showUpload = false;
	isGettingDocument = false;
	showContextMenu = false;
	x = 0;
	y = 0;

	headers = [
		{ text: 'Name', value: 'Filename' },
		{ text: 'Document Type', value: 'DocumentType.Name' },
		{
			text: 'Expiry Date',
			value: 'ExpiryDate',
			sort: (first: any, second: any) =>
				0 - (new Date(first) > new Date(second) ? -1 : 1),
		},
		{
			text: 'Date Added',
			value: 'UploadedDate',
			sort: (first: any, second: any) =>
				0 - (new Date(first) > new Date(second) ? -1 : 1),
		},
	];

	GetData() {
		const supplierId = SuppliersModule.GetSelectedSupplier.SupplierId;

		this.complianceDocumentService
			.GetDocumentData<Array<ComplianceDocument>>(
				`ComplianceDocuments?supplierId=${supplierId}&startDate=2020-01-01&endDate=2021-01-01`
			)
			.then(data => {
				this.documents = data;
				this.backupDocuments = data;
				this.isGettingDocument = false;
			});
	}

	getMandatoryDocs() {
		const supplierId = SuppliersModule.GetSelectedSupplier.SupplierId;

		this.complianceDocumentService
			.GetMandatoryDocumentData<Array<IComplianceDocument>>(
				`Compliance/Supplier/MandatoryDocuments?supplierId=${supplierId}`
			)
			.then(data => {
				this.mandatoryDocuments = data;
				this.isGettingDocument = false;
			});
	}

	mounted() {
		if (this.$route.params.documentId) {
			const supplierId = SuppliersModule.GetSelectedSupplier.SupplierId;

			this.httpService
				.Get<ComplianceDocument>(
					`Documents/ComplianceDocument?supplierId=${supplierId}&documentId=${this.$route.params.documentId}&startDate=2020-01-01&endDate=2021-01-01`
				)
				.then(data => {
					this.DownloadDocument(data);
				});
		}

		parseInt(this.$route.params.documentId);

		this.subscription = this.$store.subscribe(mutation => {
			if (mutation.type.startsWith('SelectSupplier')) {
				this.isGettingDocument = true;
				this.GetData();
				this.getMandatoryDocs();
			}
		});
	}

	beforeDestroy() {
		this.subscription();
	}

	created() {
		this.GetData();
		this.getMandatoryDocs();
	}

	ShowUploadModal() {
		this.showUpload = true;
	}

	Show(doc: ComplianceDocument, e: any) {
		if (AuthStore.IsReviewer) {
			this.selectedDocument = doc;
			e.preventDefault();
			this.showContextMenu = false;
			this.x = e.clientX;
			this.y = e.clientY;
			this.$nextTick(() => {
				this.showContextMenu = true;
			});
		}
	}

	DownloadDocument(doc: IComplianceDocument) {
		this.complianceDocument = new ComplianceDocument(doc);
		this.ShowUploadModal();
		return;
	}

	CloseModal(data: any) {
		if (data.close && !data.update) {
			this.complianceDocument = new ComplianceDocument();

			return;
		}

		const mandatoryIndex = this.mandatoryDocuments.findIndex(
			xx => xx.ComplianceId == this.complianceDocument.ComplianceId
		);

		const mandatoryDocumentIndex = this.mandatoryDocuments.findIndex(
			xx => xx.DocumentTypeId == this.complianceDocument.DocumentTypeId
		);

		const index = this.documents.findIndex(
			xx => xx.ComplianceId == this.complianceDocument.ComplianceId
		);

		if (mandatoryIndex >= 0) {
			Object.assign(
				this.mandatoryDocuments[mandatoryIndex],

				this.complianceDocument
			);
		} else if (mandatoryDocumentIndex >= 0) {
			Object.assign(
				this.mandatoryDocuments[mandatoryDocumentIndex],

				this.complianceDocument
			);
		}

		if (index >= 0) {
			Object.assign(this.documents[index], this.complianceDocument);
		} else {
			this.documents.unshift(Object.assign({}, this.complianceDocument));
		}
	}

	ShowTooltip(item: ComplianceDocument) {
		if (item.Status !== ZoneStatus.Live) {
			return true;
		}

		return false;
	}

	GetZoneStatusIndicatorColour(status: ZoneStatus) {
		switch (status) {
			case ZoneStatus.Draft:
				return 'var(--v-secondary-darken4)';

			case ZoneStatus.Live:
				return 'var(--v-primary-base)';

			case ZoneStatus.PendingReview:
				return 'var(--v-warning-base)';

			case ZoneStatus.ReviewRejected:
				return 'var(--v-error-base)';

			case ZoneStatus.PendingDelete:
				return 'var(--v-secondary-base)';

			default:
				return '';
		}
	}

	GetDocumentStatusIndicatorColour(status: DocumentStatus) {
		switch (status) {
			case DocumentStatus.InDate:
				return 'var(--v-primary-base)';

			case DocumentStatus.NoStatus:
				return 'var(--v-error-base)';

			case DocumentStatus.Expired:
				return 'var(--v-error-base)';

			case DocumentStatus.Missing:
				return 'var(--v-warning-base)';

			default:
				return '';
		}
	}
}
