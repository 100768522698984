export enum SupplierDocumentTypeEnum {
	'WasteCarrierLicence' = 7,
	'WasteManagementLicence' = 8,
	'EmployersLiabilityInsurance' = 9,
	'PublicLiabilityInsurance' = 10,
	'OperatorsLicence' = 11,
	'MotorInsurances' = 12,

	'Iso9001Certificate' = 13,
	'Iso14001Certificate' = 14,
	'Iso18001Certificate' = 15,
	'SafeContractor' = 16,
	'HealthAndSafetyPolicy' = 17,
	'RiskAssessment' = 18,
	'EnvironmentalPolicy' = 19,
	'AirsideLiabilityInsurance' = 23,
	'ConsignmentNote' = 25,
	'Iso45001Certificate' = 30,
	'Iso27001Certificate' = 31,
	'CertificateOfConformity' = 32,
	'CHAS' = 33,
	'MethodStatement' = 36,
	'SafeSystemsOfWork' = 37,
}
