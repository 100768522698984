var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"fullscreen":_vm.isFullscreen,"attach":"","origin":"left center","width":"50%"},on:{"click:outside":function($event){return _vm.CloseModal()}},model:{value:(_vm.Show),callback:function ($$v) {_vm.Show=$$v},expression:"Show"}},[_c('v-card',[_c('v-toolbar',{staticClass:"modal-toolbar",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Upload Document")]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.syncedComplianceDocument.ComplianceId)?_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.DownloadDocument}},[_vm._v("Download")]):_vm._e(),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.CloseModal}},[_vm._v("Close")])],1)],1),(_vm.isLoading)?_c('v-progress-linear',{attrs:{"color":"secondary","indeterminate":"","rounded":""}}):_vm._e(),_c('v-form',{ref:"uploadForm",staticStyle:{"padding":"10px"},attrs:{"disabled":_vm.authModule.IsReviewer},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',[(
										_vm.syncedComplianceDocument.Filename &&
											_vm.syncedComplianceDocument.DocumentStatus !== 3
									)?_c('v-text-field',{attrs:{"label":"Filename","readonly":"","disabled":"","outlined":"","append-outer-icon":"mdi-upload"},model:{value:(_vm.syncedComplianceDocument.Filename),callback:function ($$v) {_vm.$set(_vm.syncedComplianceDocument, "Filename", $$v)},expression:"syncedComplianceDocument.Filename"}}):_vm._e(),(
										!_vm.syncedComplianceDocument.Filename ||
											_vm.syncedComplianceDocument.DocumentStatus == 3
									)?_c('v-file-input',{attrs:{"id":"file-upload","truncate-length":"50","accept":"application/pdf","prepend-icon":"","append-outer-icon":"mdi-upload","label":"Upload Document *","outlined":"","rules":[
										function (v) { return !!v || 'File Required'; },
										function (v) { return !!v
												? v.type !== 'application/pdf'
													? 'Please Select a PDF File'
													: true
												: false; } ]},model:{value:(_vm.syncedComplianceDocument.ActualFile),callback:function ($$v) {_vm.$set(_vm.syncedComplianceDocument, "ActualFile", $$v)},expression:"syncedComplianceDocument.ActualFile"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.supplierDocumentTypesMapped,"rules":[
										function (v) { return (v ? !!v.Value : v || 'Document Type Required'); } ],"item-text":"Name","item-value":"Value","label":"Document Type *","required":"","outlined":"","persistent-hint":true,"return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',{staticStyle:{"padding":"5px 0"}},[_c('v-list-item-title',{staticStyle:{"font-style":"bold"}},[_vm._v(_vm._s(data.item.ServiceType))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.item.Name))])],1)]}},{key:"selection",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.Name))])]}}]),model:{value:(_vm.syncedComplianceDocument.DocumentType),callback:function ($$v) {_vm.$set(_vm.syncedComplianceDocument, "DocumentType", $$v)},expression:"syncedComplianceDocument.DocumentType"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-left":50,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
									var on = ref.on;
									var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Expiry Date","append-icon":"mdi-calendar","readonly":"","clearable":"","outlined":""},model:{value:(_vm.syncedComplianceDocument.ExpiryDateString),callback:function ($$v) {_vm.$set(_vm.syncedComplianceDocument, "ExpiryDateString", $$v)},expression:"syncedComplianceDocument.ExpiryDateString"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{on:{"input":function($event){_vm.datePicker = false}},model:{value:(_vm.syncedComplianceDocument.ExpiryDateString),callback:function ($$v) {_vm.$set(_vm.syncedComplianceDocument, "ExpiryDateString", $$v)},expression:"syncedComplianceDocument.ExpiryDateString"}})],1)],1)],1),(
								_vm.syncedComplianceDocument.DocumentType &&
									_vm.insuranceTypes.includes(
										_vm.syncedComplianceDocument.DocumentType.Value
									)
							)?_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-left":50,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.syncedComplianceDocument.DocumentType
													? _vm.syncedComplianceDocument.DocumentType.Value ===
													  _vm.supplierDocumentTypes.WasteCarrierLicence
														? 'Registration Date *'
														: 'Start Date *'
													: '',"append-icon":"mdi-calendar","readonly":"","clearable":"","outlined":"","rules":[function (v) { return !!v || 'Date Required'; }]},model:{value:(_vm.syncedComplianceDocument.StartDateString),callback:function ($$v) {_vm.$set(_vm.syncedComplianceDocument, "StartDateString", $$v)},expression:"syncedComplianceDocument.StartDateString"}},'v-text-field',attrs,false),on))]}}],null,false,3281929436),model:{value:(_vm.startDatePicker),callback:function ($$v) {_vm.startDatePicker=$$v},expression:"startDatePicker"}},[_c('v-date-picker',{on:{"input":function($event){_vm.startDatePicker = false}},model:{value:(_vm.syncedComplianceDocument.StartDateString),callback:function ($$v) {_vm.$set(_vm.syncedComplianceDocument, "StartDateString", $$v)},expression:"syncedComplianceDocument.StartDateString"}})],1)],1)],1):_vm._e(),(
								_vm.syncedComplianceDocument.DocumentType &&
									_vm.insuranceTypes.includes(
										_vm.syncedComplianceDocument.DocumentType.Value
									)
							)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.syncedComplianceDocument.DocumentType.Value ===
										_vm.supplierDocumentTypes.WasteCarrierLicence
											? 'Licence Number *'
											: 'Policy Number *',"clearable":"","outlined":"","rules":[
										function (v) { return !!v ||
											(_vm.syncedComplianceDocument.DocumentType
												? _vm.syncedComplianceDocument.DocumentType.Value ===
												  _vm.supplierDocumentTypes.WasteCarrierLicence
													? 'Licence Number Required'
													: 'Policy Number Required'
												: ''); },
										function (v) { return (v && v.toString().length < 100) ||
											'Please enter less than 100 characters'; } ]},model:{value:(_vm.syncedComplianceDocument.PolicyNumber),callback:function ($$v) {_vm.$set(_vm.syncedComplianceDocument, "PolicyNumber", $$v)},expression:"syncedComplianceDocument.PolicyNumber"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Amount *","clearable":"","outlined":"","type":"number","max":"","rules":[
										function (v) { return !!v || 'Amount Required'; },
										function (v) { return (v && v.toString().length < 10) ||
											'Please enter less than 10 characters'; } ]},model:{value:(_vm.syncedComplianceDocument.Value),callback:function ($$v) {_vm.$set(_vm.syncedComplianceDocument, "Value", $$v)},expression:"syncedComplianceDocument.Value"}})],1)],1):_vm._e(),(
								_vm.syncedComplianceDocument.DocumentType &&
									_vm.syncedComplianceDocument.DocumentType.Value ===
										_vm.supplierDocumentTypes.WasteCarrierLicence
							)?_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.issuingAuthorityTypesMapped,"rules":[
										function () { return _vm.syncedComplianceDocument.IssuingAuthority
												? _vm.syncedComplianceDocument.IssuingAuthority > 0 ||
												  'Issuing Authority Required'
												: ''; } ],"item-text":"Name","item-value":"Value","label":"Issuing Authority *","required":"","outlined":"","persistent-hint":true,"return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',{staticStyle:{"padding":"5px 0"}},[_c('v-list-item-title',{staticStyle:{"font-style":"bold"}},[_vm._v(_vm._s(data.item.ServiceType))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.item.Name))])],1)]}},{key:"selection",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.Name))])]}}],null,false,2835483637),model:{value:(_vm.syncedComplianceDocument.IssuingAuthoritySelect),callback:function ($$v) {_vm.$set(_vm.syncedComplianceDocument, "IssuingAuthoritySelect", $$v)},expression:"syncedComplianceDocument.IssuingAuthoritySelect"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.licenceTypeSelectTypesMapped,"rules":[
										function () { return _vm.syncedComplianceDocument.LicenceType
												? _vm.syncedComplianceDocument.LicenceType > 0 ||
												  'Licence Type Required'
												: ''; } ],"item-text":"Name","item-value":"Value","label":"Licence Type *","required":"","outlined":"","persistent-hint":true,"return-object":"","multiple":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',{staticStyle:{"padding":"5px 0"}},[_c('v-list-item-title',{staticStyle:{"font-style":"bold"}},[_vm._v(_vm._s(data.item.ServiceType))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.item.Name))])],1)]}}],null,false,608567977),model:{value:(_vm.syncedComplianceDocument.LicenceTypeSelect),callback:function ($$v) {_vm.$set(_vm.syncedComplianceDocument, "LicenceTypeSelect", $$v)},expression:"syncedComplianceDocument.LicenceTypeSelect"}})],1)],1):_vm._e(),(
								_vm.syncedComplianceDocument.DocumentType &&
									_vm.syncedComplianceDocument.DocumentType.Value ===
										_vm.supplierDocumentTypes.WasteCarrierLicence
							)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Licence Holder *","clearable":"","outlined":"","rules":[
										function (v) { return !!v || 'Licence Holder Required'; },
										function (v) { return (v && v.toString().length < 100) ||
											'Please enter less than 100 characters'; } ]},model:{value:(_vm.syncedComplianceDocument.LicenceHolder),callback:function ($$v) {_vm.$set(_vm.syncedComplianceDocument, "LicenceHolder", $$v)},expression:"syncedComplianceDocument.LicenceHolder"}})],1)],1):_vm._e()],1)],1),(!_vm.authModule.IsReviewer)?_c('v-footer',{attrs:{"relative":_vm.isFullscreen ? false : true,"absolute":_vm.isFullscreen ? true : false,"color":"white","height":"48px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"text-align":"right"}},[(_vm.syncedComplianceDocument.Filename)?_c('v-btn',{staticClass:"mx-2",attrs:{"block":_vm.isFullscreen ? true : false,"disabled":_vm.isLoading,"color":"error","rounded":""},on:{"click":function($event){return _vm.DeleteInternal()}}},[_vm._v("Delete")]):_vm._e(),_c('v-btn',{attrs:{"disabled":!_vm.valid || _vm.isLoading,"block":_vm.isFullscreen ? true : false,"color":"primary","rounded":""},on:{"click":function($event){_vm.syncedComplianceDocument.ComplianceId
										? _vm.EditInternal()
										: _vm.Upload()}}},[_vm._v(_vm._s(_vm.syncedComplianceDocument.Filename ? 'Update' : 'Upload'))])],1)],1)],1):_vm._e(),(
						_vm.authModule.IsReviewer &&
							(_vm.syncedComplianceDocument.Status !== null ||
								_vm.syncedComplianceDocument.Status === _vm.documentStatus.Live)
					)?_c('v-footer',{attrs:{"relative":_vm.isFullscreen ? false : true,"absolute":_vm.isFullscreen ? true : false,"color":"white","height":"48px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"text-align":"right"}},[(
									_vm.syncedComplianceDocument.Filename &&
										_vm.syncedComplianceDocument.Status !==
											_vm.documentStatus.ReviewRejected &&
										_vm.syncedComplianceDocument.Status !==
											_vm.documentStatus.PendingDelete
								)?_c('v-btn',{staticClass:"mx-2",attrs:{"block":_vm.isFullscreen ? true : false,"color":"error","rounded":""},on:{"click":function($event){_vm.showRejectPopup = true}}},[_vm._v("Reject")]):_vm._e(),_c('v-btn',{attrs:{"block":_vm.isFullscreen ? true : false,"color":"primary","rounded":""},on:{"click":function($event){return _vm.ActionDocument(true)}}},[_vm._v(" Approve ")])],1)],1)],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showRejectPopup),callback:function ($$v) {_vm.showRejectPopup=$$v},expression:"showRejectPopup"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary white--text"},[_vm._v(" Rejection ")]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-textarea',{attrs:{"label":"Rejection Reason","floating":"","rules":[
							function (v) { return !!v || 'Rejection Reason Required'; },
							function (v) { return (v && v.length > 10) ||
								'Description must be greater than 10 characters'; } ]},model:{value:(_vm.syncedComplianceDocument.RejectionReason),callback:function ($$v) {_vm.$set(_vm.syncedComplianceDocument, "RejectionReason", $$v)},expression:"syncedComplianceDocument.RejectionReason"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.isFormValid,"bottom":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.ActionDocument(false)}}},[_vm._v(" Confirm ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }